<template>
    <div class="card" v-if="project && version">
        <DataTable
            ref="roleTable"
            :value="version.tasks"
            tableClass="roleTable"
            headerClass="text-xs font-medium"
            dataKey="id"
            editMode="cell"
            scrollable
            :scrollHeight="tableHeight + 'px'"
            v-model:selection="selectedTasks"
            @rowReorder="onRowReorder"
            :pt="{table: {style:'height: 1px; width: auto'}, footer: {class:'bg-primary-900 p-2'}}"
            v-if="project.sections.length && project.roles.length"
        >
            <ColumnGroup type="header">

                <Row>
                    <Column frozen :colspan="2" :rowspan="2" headerClass="!bg-gray-100" v-if="isProjectAdmin"></Column>
                    <Column :header="section.name" :rowspan="2" v-for="section in project.sections" :key="section.id" frozen headerClass="!bg-gray-100 align-bottom text-sm" :pt="{columnHeaderContent: {style: 'max-with: 300px; width: ' + (section.width ?? 'auto') + 'px'}}"></Column>
                    <Column :header="role.name" :colspan="role.children.length" v-for="role in project.roles" :key="role.id" headerClass="text-sm border-r border-b-0 opacity-70" :style="'background-color: #' + role.color" />
                </Row>
                <Row>
                    <Column v-for="child in project.roles.map(role => role.children.map((child) => child)).flat()" :key="child.id" headerClass="bg-stone-50 border-r p-2 text-stone-900 text-xs text-center cursor-pointer hover:opacity-80" :pt="{headerCell: {class: 'hover:text-primary-950', style: 'background-color: #' + (child.user ? project.roles.find((e) => e.id == child.parent_id).color : 'EEEEEE')},}">
                        <template #header>
                            <div class="flex flex-col items-center" @click="selectRole(child)">
                                <div class="absolute" style="top:36px;">
                                    <Avatar icon="pi pi-hourglass" class="opacity-80 !w-7 !h-7" v-tooltip="'Awaiting signature'" style="background-color: #ffffff; color: #2a1261" shape="square" v-if="child.agreements && child.agreements.length > 0 && !child.agreements[0].signature" />
                                    <Avatar icon="pi pi-ban" class="opacity-80 !w-7 !h-7" v-tooltip="'No user assigned'" style="background-color: #ffffff; color: #2a1261" shape="square" v-else-if="!child.user" />
                                    <Avatar icon="pi pi-send" class="opacity-80 !w-7 !h-7" v-tooltip="'Role updated. New agreement must be sent'" style="background-color: #ffffff; color: #2a1261" shape="square" v-else-if="updatedRoles.map(e => e.id).includes(child.id)" />
                                </div>
                                <div class="font-light headerRole" @mouseenter="displayRole($event, child)" @mouseleave="hidePopover()">
                                    {{ child.name }}
                                </div>
                            </div>
                        </template>
                    </Column>
                </Row>
            </ColumnGroup>
            <Column class="rowReorder" frozen rowReorder :reorderableColumn="false" bodyClass="text-xs !bg-gray-50" bodyStyle="width: 2rem" v-if="isProjectAdmin" />
            <Column frozen bodyClass="!bg-gray-50" bodyStyle="width: 2rem" v-if="isProjectAdmin">
                <template #body="slotProps">
                    <span class="pi pi-cog cursor-pointer" :id="'cod-' + slotProps.data.id" @click="toggleActions($event, slotProps.index)" />
                </template>
            </Column>
            <Column
                field="section.name"
                bodyClass="text-xs !bg-gray-50"
                frozen
                style="width: 150px"
                v-for="(section, sectionIndex) in project.sections" :key="section.id"
            >
                <template #body="slotProps">
                    <div v-if="version.tasks[slotProps.index] && version.tasks[slotProps.index].sections[sectionIndex] && version.tasks[slotProps.index].sections[sectionIndex].pivot && version.tasks[slotProps.index].sections[sectionIndex].pivot.value">
                        {{ version.tasks[slotProps.index].sections[sectionIndex].pivot.value }}
                    </div>
                    <div v-else-if="sectionIndex==0 && isProjectAdmin">
                        <i class="text-xs text-gray-400">To complete...</i>
                    </div>
                </template>
                <template #editor="slotProps">
                    <InputGroup
                    :fluid="true"
                        class="p-0"
                        v-if="version.tasks[slotProps.index] && version.tasks[slotProps.index].sections[sectionIndex].pivot && isProjectAdmin"
                    >
                        <AutoComplete
                            v-model="version.tasks[slotProps.index].sections[sectionIndex].pivot.value"
                            :suggestions="[...new Set(version.tasks.map((e) => e.sections[sectionIndex].pivot ? e.sections[sectionIndex].pivot.value : ''))]"
                            :delay="100"
                            inputClass="w-full p-0 h-8"
                            :pt="{ root: {style: 'padding: 0; width:100%;'}, option: {style: 'font-size:12px'}, pcInput: {style:'height: 20px; padding:0px; font-size: 28px'}}"
                            :disabled="isSaving"
                            @blur="saveProjectVersion(true)"
                        />
                        <Button icon="pi pi-times" text :pt="{root: {class: 'ml-2'}, label: {class:'hidden'}}" @click="version.tasks[slotProps.index].sections[sectionIndex].pivot.value=''" />
                    </InputGroup>
                     <div class="px-2" v-else-if="version.tasks[slotProps.index].sections && version.tasks[slotProps.index].sections[sectionIndex] && version.tasks[slotProps.index].sections[sectionIndex].pivot">
                        {{ version.tasks[slotProps.index].sections[sectionIndex].pivot.value }}
                    </div>
                </template>
            </Column>
            <Column v-for="child in project.roles.map(role => role.children.map((child) => child)).flat()" :key="child.id" headerClass="text-xs" bodyClass="border-x" style="min-width: 50px"  :pt="{bodyCell: {class: '!p-0 border-x'}, footer: {class:'bg-stone-700'}}">
                <template #body="slotProps">
                    <div :class="['w-full h-full flex items-center justify-center', {'cursor-pointer' : isProjectAdmin}]" @click="toggleCodes($event, slotProps.index, getRole(slotProps.index, child.id))">
                        <div
                            class="flex w-8 justify-center p-2 rounded text-xs"
                            :style="'background-color: #' + getRoleRaciCodeValue(slotProps.index, child.id).color"
                            v-if="getRoleRaciCodeValue(slotProps.index, child.id)"
                        >
                            {{  getRoleRaciCodeValue(slotProps.index, child.id).code }}
                        </div>
                    </div>
                </template>
            </Column>
            <template #footer>
                <div class="flex justify-between">
                    <div>
                        <Button icon="pi pi-plus" class="text-white ml-24" label="Add a new task" outlined @click="addNew" :pt="{label: {class: 'font-medium'}, root: {class: 'border px-2 py-1 border-white rounded'}}" v-if="isProjectAdmin && version" :disabled="scrollDown" />
                    </div>
                    <div class="flex items-center" v-if="version">
                        <Button icon="pi pi-send" class="text-white mr-5" label="Send agreements" @click="sendAgreementDialog=true" :pt="{label: {class: 'font-medium'}, root: {class: 'border px-2 py-1 border-white rounded'}}" v-if="version && updatedRoles.length" />
                        <Button icon="pi pi-tag" class="text-white mr-5" :label="version.name + (version.name ? '.' : '') + version.number" @click="versionDialog=true" :pt="{label: {class: 'font-medium'}, root: {class: 'border px-2 py-1 border-white rounded'}}" v-if="version" />
                        <Button icon="pi pi-external-link" class="text-white mr-2" label="Export CSV" outlined @click="exportCSV" :pt="{label: {class: 'font-medium'}, root: {class: 'border px-2 py-1 border-white rounded'}}" />
                    </div>
                </div>
            </template>
        </DataTable>

        <div class="container flex justify-center align-items-center flex-wrap pt-8" v-else>
            <Message severity="warn" :closable="false" class="mw-64 h-32 text-center">
                <div class="mb-5"><i class="pi pi-exclamation-triangle"></i> This project needs sections and roles.</div>
                <NuxtLink to="/setup">
                    <div class="rounded bg-primary inline-block text-white px-4 py-2">Setup</div>
                </NuxtLink>
            </Message>
        </div>

        <Popover ref="op" >
            <Listbox v-model="selectedCodeId" scrollHeight="15rem" :options="raciCodes" optionLabel="code" optionValue="id" :pt="{root: {class: 'border-0'}, list: {style: 'padding: 4px 0'}, option: {style: 'justify-content: center'}}" @update:modelValue="setCode">
                    <template #option="slotProps">
                        <div v-if="slotProps.option.id > 0">
                            {{ slotProps.option.code }}
                        </div>
                        <div class="text-red-700" v-else>
                            <i class="pi pi-trash"></i> Remove
                        </div>
                    </template>
            </Listbox>
        </Popover>
        <Popover ref="op_actions" :pt="{root: {class: 'border-0'}}">
            <Menu :model="actions">
                <template #item="slotProps">
                    <a v-ripple class="flex align-items-center" v-bind="slotProps.props.action">
                        <span :class="slotProps.item.icon" />
                        <span class="ml-2">{{ slotProps.item.label }}</span>
                    </a>
                </template>
            </Menu>
        </Popover>
        <Dialog v-model:visible="deleteTaskDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
            <div class="flex items-center gap-4">
                <i class="pi pi-exclamation-triangle !text-3xl" />
                <span>Are you sure you want to delete this task?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" text @click="deleteTaskDialog = false" />
                <Button label="Yes" icon="pi pi-check" text @click="deleteTask" />
            </template>
        </Dialog>

        <Popover ref="op_role">
            <div v-if="selectedRole" class="rounded flex flex-col max-w-96">
                <div class="px-4 py-2">
                    <div>
                        <div class="font-medium mt-1">{{ selectedRole.name }}</div>
                        <div class="text-xs font-light pb-2">{{ selectedRole.description }}</div>
                        <div class="text-primary flex items-center" v-if="selectedRole.user"><span class="pi pi-user mr-2"></span>{{ selectedRole.user.name }}</div>

                        <div class="text-yellow-500 flex items-center" v-else><span class="pi pi-ban mr-2"></span> No one is assigned</div>
                    </div>
                </div>
            </div>
        </Popover>

        <Dialog v-model:visible="versionDialog" :style="{ width: '450px' }" header="Version" :modal="true">
            <div>
                <label for="name" class="block font-bold mb-3">Name + number </label>
                <InputGroup>
                    <InputText id="name" v-model.trim="version.name" required="true" autofocus :invalid="submitted && !version.name" fluid />
                    <InputGroupAddon>.{{ version.number }}</InputGroupAddon>
                    <Button icon="pi pi-save" @click="saveProjectVersion" />
                </InputGroup>
                <small v-if="submitted && !version.name" class="text-red-500">Name is required.</small>
            </div>

            <template #footer>
                <div class="w-full flex justify-between">
                    <Button icon="pi pi-trash" label="Delete" severity="danger" outlined="" @click="deleteVersionDialog=true" />
                    <Button icon="pi pi-plus" label="New version" outlined @click="addNewVersion" />
                </div>
            </template>
        </Dialog>

        <Dialog v-model:visible="deleteVersionDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
            <div class="flex items-center gap-4">
                <i class="pi pi-exclamation-triangle !text-3xl" />
                <span>Are you sure you want to delete the version number {{version.number}}?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" text @click="deleteVersionDialog = false" />
                <Button label="Yes" icon="pi pi-check" text @click="deleteVersion" />
            </template>
        </Dialog>

        <Dialog v-model:visible="sendAgreementDialog" :style="{ width: '450px' }" header="Send agreements" :modal="true">
            <div>
                <div class="mb-5">The following roles have been updated.<br /> Do you want send a new agreement to these users ?</div>
                <DataTable :value="updatedRoles" scrollable scrollHeight="300px">
                    <Column field="name" header="Role"></Column>
                    <Column field="user.name" header="User"></Column>
                </DataTable>

            </div>

            <template #footer>
                <Button label="Cancel" icon="pi pi-times" text @click="sendAgreementDialog=false" />
                <Button label="Send" icon="pi pi-send" @click="sendAgreements" />
            </template>
        </Dialog>

        <Toast />

    </div>
</template>

<script>
import mainMixin from '~/mixins/main.mixin.js';

export default {
    mixins: [mainMixin],
    data() {
        return {
            version: useState('version'),
            versionDialog: false,
            deleteVersionDialog: false,
            sendAgreementDialog: false,
            isSaving: false,
            selectedRow: null,
            selectedRole: null,
            selectedCodeId: null,
            selectedTasks: null,
            deleteTaskDialog: false,
            tableHeight: 400,
            scrollDown: false,
            timer: null,
            actions: [
                {
                    label: 'Duplicate',
                    icon: 'pi pi-copy',
                    command: () => {
                        this.duplicateTask();
                    }
                },
                {
                    label: 'Create new task below',
                    icon: 'pi pi-plus',
                    command: () => {
                        this.addNew(false,true);
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.confirmDeleteTask();
                    }
                },
            ]
        };
    },
    methods: {
        async loadVersion(){
            this.timer = null;
            this.loadProject();
            if(this.project){
                if(this.project.versions.length == 0){
                    this.version = {id: null, project_id: this.project.id, name: '', number: 1, tasks: []};
                }else{
                    this.version = await this.client('/api/project-versions/' + this.project.versions[0].id);
                }
            }
        },
        async loadProject(){
            if(this.project){
                this.project = await this.client('/api/projects/' + this.project.id);
            }
            this.timer = setTimeout(() => this.loadProject(), 1000 * 60 * 5);
        },
        addNewVersion(){
            this.version.id = null;
            this.version.number++;
            this.version.tasks.forEach(task => {
                task.id = null;
            });
            this.saveProjectVersion(true);
            this.$toast.add({ severity: 'success', summary: 'New version', detail: 'The version number ' + this.version.number + ' has been created.', life: 3000 });
        },
        deleteVersion(){
            this.deleteVersionDialog = false;
            this.versionDialog = false;
            this.project.versions.shift();
            const number = this.version.number;

            this.client('/api/project-versions/' + this.version.id, {
                method: 'DELETE'
            }).then(_ => this.loadVersion());
            this.$toast.add({ severity: 'success', summary: 'Version deleted', detail: 'The version number ' + number + ' has been deleted.', life: 3000 });

        },
        getRole(taskIndex, role_id){
            if(this.version.tasks[taskIndex]){
                const role = this.version.tasks[taskIndex].roles.find(e => e.id == role_id);
                if(role){
                    return role;
                }else{
                    const newRole = JSON.parse(JSON.stringify(this.project.roles.find((e) => e.children.map(f => f.id).includes(role_id)).children.find(g => g.id == role_id)));
                    return { ...newRole,
                        ...{
                            pivot: {
                                task_id: this.version.tasks[taskIndex].id,
                                role_id: role_id,
                                raci_code_id: null
                            }
                        }
                    };
                }
            }
        },
        getRoleRaciCodeValue(taskIndex, role_id){
            const role = this.getRole(taskIndex, role_id);
            if(role){
                return this.project.raci_codes.find((e) => e.id == role.pivot.raci_code_id);
            }
            return 'EEEEEE';
        },
        toggleCodes(event, index, role){
            if(this.isProjectAdmin){
                this.selectedRow = this.version.tasks[index];
                if(!this.version.tasks[index].roles.find(e => e.id == role.id)){
                    this.version.tasks[index].roles.push(role);
                    this.toggleCodes(event, index, this.version.tasks[index].roles[this.version.tasks[index].roles.length-1]);
                }else{
                    this.selectedRole = role;
                    this.selectedCodeId = role.pivot.raci_code_id;
                    this.$refs.op.toggle(event);
                }
            }
        },
        setCode(){
            this.selectedRole.pivot.raci_code_id = this.selectedCodeId;
            this.selectedRole.updated = true;
            this.$refs.op.hide();
            this.saveProjectVersion();
        },
        toggleActions(event, index){
            this.selectedRow = this.version.tasks[index];
            this.$refs.op_actions.toggle(event);
        },
        duplicateTask(){
            this.addNew(true, true);
        },
        confirmDeleteTask()
        {
            this.deleteTaskDialog = true;
            this.$refs.op_actions.hide();
        },
        deleteTask(){
            this.version.tasks = this.version.tasks.filter(val => this.selectedRow.id != val.id);
            this.selectedRow = null;
            this.deleteTaskDialog = false;
            this.saveProjectVersion();
        },
        addNew(copy=false, fromRow=false){
            let newTask;
            if(!fromRow){
                this.selectedRow = false;
            }
            if(this.selectedRow && copy){
                newTask = JSON.parse(JSON.stringify(this.selectedRow));
                newTask.id = null;
                newTask.position = newTask.position + 1;
            }else{
                newTask = {
                    position: this.selectedRow ? (this.selectedRow.position+1) : this.version.tasks.length,
                    sections: this.project.sections,
                    roles: [],
                }
                this.scrollDown = true;

            }
            this.version.tasks.splice(newTask.position, 0, newTask);
            this.selectedRow = null;
            this.$refs.op_actions.hide();
            this.onRowReorder(null);
        },
        onRowReorder(event) {
            if(event){
                this.version.tasks = event.value;
            }
            this.version.tasks.forEach((task, index) => {
                task.position = index
            })
            this.saveProjectVersion();
        },
        saveProjectVersion(loadProject=false){
            this.versionDialog = false;
            this.isSaving = true;
            setTimeout(async () => {
                this.project.action = 'tasks';
                if(this.version.id){
                    this.version = await this.client('/api/project-versions/' + this.version.id, {
                        method: "PUT",
                        body: this.version
                    });
                }else{
                    this.version = await this.client('/api/project-versions', {
                        method: "POST",
                        body: this.version
                    });
                }
                if(loadProject){
                    this.project = await this.client('/api/projects/' + this.project.id);
                }

                this.isSaving = false;
                if(this.scrollDown){
                    const roleTableElement = document.querySelector('.roleTable');
                    if (roleTableElement) {
                        const parentElement = roleTableElement.parentElement;
                        if (parentElement) {
                            parentElement.scrollTop = parentElement.scrollHeight;
                        }
                    }
                    this.scrollDown = false;
                }
            }, 100);
        },
        selectRole(role){
            this.$router.push('/roles/' + this.project.id + '-' + role.parent_id + '-' + role.id);
        },
        displayRole(event, role) {
            if (this.selectedRole === role) {
                this.$refs.op_role.hide();
                this.selectedRole = null;
            } else {
                this.hidePopover();
                this.selectedRole= role;
                this.$refs.op_role.show(event);
            }
        },
        hidePopover() {
            this.$refs.op_role.hide();
        },
        onResize(){
            this.tableHeight = (window.innerHeight - 105);
        },
        exportCSV(){
            let csv = "";

            //Header 1
            this.project.sections.forEach(section => {
                csv += ";";
            });
            this.project.roles.forEach(role => {
                csv += role.name + ";";
                role.children.forEach((child, index) => {
                    if(index > 0){
                        csv += ";";
                    }
                });
            });
            csv += "\n";

            //Header 2
            this.project.sections.forEach(section => {
                csv += section.name + ";";
            });
            this.project.roles.forEach(role => {
                role.children.forEach((child, index) => {
                    csv += child.name + ";";
                });
            });
            csv += "\n";

            //Tasks
            this.version.tasks.forEach(task => {
                task.sections.forEach(section => {
                    csv += (section.pivot.value ?? '') + ";";
                })
                this.project.roles.forEach(role => {
                    role.children.forEach(child => {
                        const roleValue = task.roles.find(e => e.id == child.id);
                        if(roleValue){
                            const raciCode = this.project.raci_codes.find(e => e.id == roleValue.pivot.raci_code_id);
                            csv += raciCode.code + ";";
                        }else{
                            csv += ";";
                        }

                    });

                });
                csv += "\n";
            });

            const csv_link = document.createElement('a');
            csv_link.href = 'data:text/csv;chartset-utf-8,' + encodeURI(csv);
            csv_link.target = '_blank';
            csv_link.download = 'raci_' + this.project.name + '.csv';
            csv_link.click();
        },
        sendAgreements(){
            for(const role of this.updatedRoles){
                const agreement = {
                    id: null,
                    role_id: role.id,
                    old_user_id: role.user_id,
                    new_user_id: role.user_id,
                };
                this.project.roles.find((e) => e.children.map(f => f.id).includes(role.id)).children.find(g => g.id == role.id).agreements.unshift(agreement);
            }
            this.saveProject();
            this.sendAgreementDialog = false;
        },
        async saveProject(){
            this.project.action = 'roles';
            this.project = await this.client('/api/projects/' + this.project.id, {
                method: "PUT",
                body: this.project
            });
            this.loadVersion();
        },
    },
    computed:{
        raciCodes(){
            const codes = [ ...this.project.raci_codes, {
                    id: 0,
                    code: '- empty -',
                    color: '',
                    icon: 'pi pi-plus',
                }];
            return codes;
        },
        updatedRoles(){
            const roles = this.project.roles.map(role => role.children.map((child) => child)).flat();
            let rolesupdated = [];
            if(roles.length && this.version){
                for(const role of roles){
                    for(const task of this.version.tasks){
                        const taskRole = task.roles.find(e => e.id == role.id);
                        if(taskRole && role.agreements.length && taskRole.pivot.raci_code_id && this.$dayjs(taskRole.pivot.updated_at) > this.$dayjs(role.agreements[0].created_at)){
                            if(!rolesupdated.map(e => e.id).includes(role.id)){
                                rolesupdated.push(role);
                            }
                        }
                    }
                }
            }
            return rolesupdated;
        }
    },
    mounted() {
        this.loadVersion();
        this.$nextTick(function () {
        this.onResize();
        })
        window.addEventListener('resize', this.onResize)
  },
};
</script>

<style scoped>
.p-datatable-column-title {
   font-weight: 200 !important;
}
</style>
